import { DateTime } from "luxon"
import { zeroPadding, Logger } from "packs/common"
import DateTag from "packs/models/DateTag"
import Event from "packs/models/Event"
import Util from "packs/utils/Util"
import axios from "axios"
import Notice from "packs/models/Notice"
import Room from "packs/models/Room"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import RoomStorage from "packs/models/RoomStorage"
import PossibleDate from "packs/models/PossibleDate"
import _PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomMember from "packs/models/RoomMember"
import _RoomManager from "packs/models/RoomManager"
import NotificationControl from "packs/utils/NotificationControl"
import RoomSetting from "packs/models/RoomSetting"
import FormField from "packs/models/FormField"
import lodash from "lodash"
import LocationTag from "./LocationTag"
import CalendarUtil from "packs/utils/CalendarUtil"

export default class AvailableScheduleTag {
    constructor(
        // v8移行
        public available_days_of_weeks: DateTag[], //[1,2,3]で曜日の繰り返しは持っておく.
        public id: string,
        public name: string,
        public updated_at: number,
        // v8移行
        public location_name: string,

        // none,guess_or_none,guess_or_fixed,guess_or_minimum
        // v8移行
        public travel_time_mode: string,
        // v8移行
        public additional_fixed_travel_min: number,

        // v8移行
        // 調整可能期間（相対）
        public available_period_start_days: number,
        // v8移行
        public available_period_end_days: number,
        // v8移行
        // 調整可能期間（絶対）
        public available_start_date: number,
        // v8移行
        public available_end_date: number,

        // v8移行
        public fixed_travel_before_min: number,
        // v8移行
        public fixed_travel_after_min: number,
        // public optional_add_schedules: any,
        // public optional_remove_schedules: any,
        public optional_schedules: any,

        // 調整ページで利用しているparent_room_idがArrayで入っています。
        public rooms: string[],

        // NORMAL=1,DEFAULT=2,NOT_ATTEND=10,TEMPORARY=50（メールで相手が提案してきた内容が入ります）
        public type: number,

        // Array<{ ctag_id: string, ignore_allday: boolean }>
        // public calendar_tags: [],
        public meeting_attendees_groups: MeetingAttendeesGroup[],
        public can_connect_calendar: boolean,
        // v8移行
        public assign_mag_rule: string, // order, random, equal, priority
        public period_days_for_assign: number,
        // v8移行
        public is_private: boolean,
        public can_select_mag: boolean,
        // v8移行
        public mag_type: string, // display_none, display_and_selectable, display_after_fix

        public selected: boolean,
        public color: string,
        public optional_calendar_tag_search_key: string,

        // v8移行
        public available_period_skip_type: string, // none,weekend,weekend_and_holiday,custom,holiday
        // v8移行
        public available_period_skip_custom: number[], // [0,1,2,3,4,5,6,10] 10=holiday
        public meeting_room_resources: any[],
        public online_meeting_resources: any[],

        // v8移行
        public can_sync_travel_time_to_calendar_event: boolean,
        // v8移行
        public sync_travel_time_event_name: string,
        // 最終更新日時（アップデートした際にセルを動的に更新するために利用）
        public systemUpdatedAt: number,

        // v8追加
        // required_attendeesのctagの予定を確認せず、optional_schedulesの予定のみを確認します.
        public is_only_use_optional_schedules: boolean,
        // オプション設定が有効かどうか
        public is_active_meeting_attendee_option: boolean,
        public ignore_allday: boolean,
        // イベント名での対応可能時間判定の設定有無
        public can_use_available_event_keyword: boolean,
        public available_event_keyword: string,
        public available_event_allday_start_hour: number,
        public available_event_allday_start_min: number,
        public available_event_allday_end_hour: number,
        public available_event_allday_end_min: number,
        public can_use_ignore_event_keyword: boolean,
        public ignore_event_keyword: string,
        public emails: string,
        public is_active_meeting_attendees_group_option: boolean,
        public daily_meeting_max_num: number,
        public daily_countable_event_keyword: string,
        public is_shared: boolean,
        public user_id: string,
        public owner: RoomMember,
        public has_detail: boolean
    ) {}

    static copy(o: AvailableScheduleTag): AvailableScheduleTag {
        // let clone = Object.assign(Object.create(Object.getPrototypeOf(o)), o)
        let newO = lodash.cloneDeep(o)
        return newO
    }

    static fetchFromJson(astagsJson: any): AvailableScheduleTag[] {
        let astags = []
        // const arrOfDateTag = JSON.parse(astagsJson)
        // Logger(`arrOfDateTag: ${arrOfDateTag.length}`)
        for (let _ast of astagsJson) {
            const _astag: AvailableScheduleTag = _ast
            _astag.is_active_meeting_attendee_option = true
            let addSchedules = {}
            Object.entries(_astag.optional_schedules).forEach(([date, events]) => {
                let _events: Event[] = events as any
                let evs = []
                for (let ev of _events) {
                    ev.systemUpdatedAt = Util.getSec() + ev.start_time
                    evs.push(ev)
                }
                addSchedules[date] = evs
            })
            _astag.optional_schedules = addSchedules

            // let removeSchedules = {}
            // Object.entries(_astag.optional_remove_schedules).forEach(([date, events]) => {
            //     let _events: Event[] = events as any
            //     let evs = []
            //     for (let ev of _events) {
            //         ev.systemUpdatedAt = Util.getSec() + ev.start_time
            //         evs.push(ev)
            //     }
            //     removeSchedules[date] = evs
            // })
            // _astag.optional_remove_schedules = removeSchedules

            // Logger(`_astag.meeting_attendees_groups: ${Util.output(_astag.meeting_attendees_groups)}`)

            const _mags = MeetingAttendeesGroup.fetchFromJson(_astag.meeting_attendees_groups)
            _astag.meeting_attendees_groups = _mags
            if (!_astag.color) {
                _astag.color = Util.getRandomColor()
            }

            // let events = DateTag.toWeeklyEvents(_dateTag)
            // Logger(`events: ${events}`)
            astags.push(_astag)
        }
        return astags
    }

    /**
     * SelectTagに並べるための時間[Array]を返します。[0..24]
     */
    static getSelectHours(): number[] {
        // return Array(24)
        //     .fill(0)
        //     .map((_, i) => i)
        return Array(16)
            .fill(0)
            .map((_, i) => i + 8)
    }

    /**
     * SelectTagに並べるための分（5分刻み）を返します。[0,5,・・]
     */
    static getSelectMins(): any[] {
        return Array(12)
            .fill(0)
            .map((_, i) => zeroPadding(`${i * 5}`))
    }

    /**
     * 年月日 時間のフォーマットのstringを返します。
     * @param sec unixTime
     */
    static getTimeStringFromSeconds(sec: number): string {
        let format = DateTime.fromSeconds(sec).toFormat("yyyy年MM月dd日 HH:mm")
        return format
    }

    /**
     * なにもないときのデフォルトのastagを作成します。
     */
    static createDefaultAstag(): AvailableScheduleTag {
        let color = Util.getRandomColor()
        // [DateTag.createWeekDayMorning(), DateTag.createWeekDayAfternoon()]
        return new AvailableScheduleTag(
            [],
            "newId",
            "無名",
            Util.getSec(),
            "",
            "none",
            0,
            null,
            null,
            null,
            null,
            null,
            null,
            {},
            [],
            1,
            [MeetingAttendeesGroup.createDefault()],
            false,
            "order",
            30,
            false,
            false,
            `display_none`,
            true,
            color,
            null,
            `none`,
            null,
            null,
            null,
            false,
            null,
            Util.getSec(),
            false,
            true,
            null,
            false,
            null,
            null,
            null,
            null,
            null,
            false,
            null,
            null,
            false,
            -1,
            null,
            false,
            null,
            null,
            true
        )
    }

    /**
     * イベントを追加するctags & {email:, name:}を入れたastagを作成します.
     * @param registrable_attendees [CalendarTag[] or Hash<string, string>]
     * @param fields FormField[]
     * @param uf RoomMember 本人の
     * @returns
     */
    static createGuestRegistrableAstag(registrable_attendees: any[], astag: AvailableScheduleTag = null): AvailableScheduleTag {
        // fields = fields ? fields : []

        // 出席者に自分を追加します. その1
        // if (uf.email && uf.name) {
        //     let att = registrable_attendees.find(att => att.email == uf.email)
        //     if (!att) {
        //         registrable_attendees.push({ name: uf.name, email: uf.email })
        //     }
        // }

        // 出席者に自分を追加します. その2
        // let ffName = fields.find(f => f.field_name == `attendee1_name`)
        // let ffEmail = fields.find(f => f.field_name == `attendee1_email`)

        // if (ffName && ffEmail) {
        //     let att = registrable_attendees.find(att => att.email == ffEmail)
        //     if (!att) {
        //         registrable_attendees.push({ name: ffName, email: ffEmail })
        //     }
        // }

        if (!astag) {
            astag = this.createDefaultAstag()
            astag.id = null
        }

        let mag = astag.meeting_attendees_groups[0]
        mag.registrable_attendees = registrable_attendees

        return astag
    }

    /**
     * 仲介不参加時のastagを作成します。
     */
    static createChukaiAstag(): AvailableScheduleTag {
        let astag = this.createDefaultAstag()
        astag.type = 10
        astag.available_days_of_weeks = []
        return astag
    }

    static getAstagDetail(astagId) {
        let url = `${Util.prefixUrl}/available_schedule_tags/${astagId}`

        return axios({
            method: `get`,
            url: url,
        })
            .then(res => {
                Logger(`AvailableScheduleTag#getAstagDetail: message:${res.data.message}`)

                const _astag: AvailableScheduleTag = AvailableScheduleTag.fetchFromJson([res.data.astag])[0]
                Logger(`RETURN astag:${Util.output(_astag)}`)
                let _shared_loctags = null
                if (_astag.is_shared) {
                    _shared_loctags = LocationTag.fetchFromJson(res.data.shared_locations)
                }

                return { astag: _astag, shared_loctags: _shared_loctags }
            })
            .catch(err => {
                Logger(`err: ${err.message}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    /**
     * 新規のカレンダーグループを作成・更新します。
     * @param title タイトル
     * @param datetag AvailableScheduleTag
     * @param optionalAddDate astag以外で個別に追加した日時
     * @param optionalRemoveDate astagの中から別途削除した日時
     * @param sendType [string] post（作成） or put（更新） or createAndShare（作成して共有 ワンタイム）
     * @param parentId [string] 作成して共有する場合のみ、parentIdが必要.
     */
    static saveAvailableSchedule(astag: AvailableScheduleTag, sendType, parentId?): Promise<AvailableScheduleTag> {
        // 新規のカレンダーグループを作成します。うまく作成できたら、カレンダーグループ一覧トップページに戻ります。
        // const ctagIds = getCtagIds(ctags)
        // 新規の場合はIDをnilにする.
        let url = `${Util.prefixUrl}/available_schedule_tags`
        if (sendType == "post") {
            delete astag.id
        }

        // 不参加カレンダーの場合calendar_tagsは不要です.
        // if (astag.type == 10) {
        //     delete astag.calendar_tags
        // }

        // パラメータの内容から調整を加えます。
        astag = AvailableScheduleTag.adjustForSendAstag(astag)

        // if (astag.meeting_attendees_groups && astag.meeting_attendees_groups.length > 0) {
        //     let mags = [...astag.meeting_attendees_groups]
        //     // MeetingAttendeesGroup.deleteKeys(mags)
        // }

        Logger(`send data: available_time: ${Util.output(astag)}`)
        let dic = {
            available_time: astag,
        }

        if (sendType == `createAndShare`) {
            if (astag.id == "newId") delete astag.id
            url += `/create_and_share`
            dic["parent_room_id"] = parentId
            sendType = `post`
        }

        return axios({
            method: sendType,
            url: url,
            data: dic,
        })
            .then(res => {
                Logger(`res: ${res.data.message}`)
                Notice.message = `${res.data.message}`
                const _astag: AvailableScheduleTag = AvailableScheduleTag.fetchFromJson([res.data.astag])[0]
                Logger(`RETURN astag: ${Util.output(_astag)}`)
                // if (astags.length > 0) {
                //     astag = astags[0]
                //     return astag
                // }

                // カレンダーグループ一覧トップページに遷移.
                // $router.push("/")
                return _astag
            })
            .catch(err => {
                Logger(`err: ${err.message}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    /**
     * 調整カレンダーを削除します。
     * @param astag [AvailableScheduleTag]
     */
    static delete(astag: AvailableScheduleTag): Promise<AvailableScheduleTag> {
        let url = `${Util.prefixUrl}/available_schedule_tags`
        return axios({
            method: `delete`,
            url: url,
            data: { id: astag.id },
        })
            .then(res => {
                Logger(`res: ${res.data.message}`)
                Notice.message = `${res.data.message}`
                const _astag: AvailableScheduleTag = res.data.astag
                Logger(`削除したastag: ${Util.output(_astag)}`)
                return _astag
            })
            .catch(err => {
                Logger(`err: ${err.message}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    /**
     * パラメータの内容から送信前に調整を入れます。
     * @param astag
     */
    static adjustForSendAstag(astag: AvailableScheduleTag): AvailableScheduleTag {
        if (astag.travel_time_mode) {
            // let options = this.travelTimeModeOptions().map(e => e.mode)
            if ([`none`, `guess_or_none`].includes(astag.travel_time_mode)) {
                astag.fixed_travel_before_min = 0
                astag.fixed_travel_after_min = 0
            }
            if ([`none`, `fixed`].includes(astag.travel_time_mode)) {
                astag.location_name = null
            }
        } else {
            astag.travel_time_mode = `none`
            astag.fixed_travel_before_min = 0
            astag.fixed_travel_after_min = 0
            astag.location_name = null
        }

        let mags = astag.meeting_attendees_groups
        if (Util.isPresent(mags)) {
            mags = mags.filter(m => Util.isPresent(m))
        }
        // if (Util.isPresent(astag.meeting_attendees_groups)) {
        //     astag.can_connect_calendar = true
        // }
        // magsがあっても、空だったらcan_connect_calendarをオフにします。
        if (Util.isPresent(astag.meeting_attendees_groups)) {
            astag.can_connect_calendar = true
            let normalMags = astag.meeting_attendees_groups.filter(m => MeetingAttendeesGroup.isPresent(m))
            let instantMags = astag.meeting_attendees_groups.filter(m => MeetingAttendeesGroup.isInstant(m))
            Logger(`AvailableScheduleTag.adjustForSendAstag _mags:${Util.output(normalMags)}`)
            if (Util.isBlank(normalMags) && Util.isBlank(instantMags)) {
                astag.meeting_attendees_groups = []
                astag.can_connect_calendar = false
            }
            if (Util.isBlank(normalMags)) {
                astag.can_connect_calendar = false
            }
        } else {
            astag.can_connect_calendar = false
        }

        delete astag.rooms
        return astag
    }

    /**
     * 調整カレンダーページでのプレビューで表示する情報を叩きます。
     * @param astag [AvailableScheduleTag]
     * @param userInfo [RoomMember]
     * @param is_owner [boolean] オーナーでないユーザーとしてこのデータを取得する場合 falseにします。（通常はtrue）
     */
    static getPossibleDatesPreview(
        astag: AvailableScheduleTag,
        userInfo: RoomMember,
        duration = 60,
        is_owner: boolean = true,
        location_name = null,
        rs: RoomSetting = null,
        months: string[] = null,
        needRefresh: boolean = false,
        max_bookings_num = 1,
        room = null,
        weeks: string[] = null,
        calendar_show_type = null
    ) {
        // パラメータの内容から調整を加えます。
        astag = AvailableScheduleTag.adjustForSendAstag(astag)

        if (astag.id == `newId`) {
            astag.id = null
        }

        let params = {
            available_time: astag,
            is_owner,
            duration,
            location_name,
            room_setting: rs,
            months: months,
            max_bookings_num,
            public_room_id: room ? room.id : null,
            weeks: weeks,
            calendar_show_type: calendar_show_type,
        }

        return axios
            .post(`${Util.prefixUrl}/available_schedule_tags/possible_dates_preview`, params)
            .then(result => {
                Logger(`AvailableScheduleTag.getPossibleDatesPreview data:${Util.output(result.data)}`)
                // let possible_dates = result.data.possible_dates
                let _dic = result.data
                let searched_months = _dic.months

                if (Util.isPresent(months)) {
                    let searched_months_nums = searched_months.map(m => parseInt(m))
                    for (let _num of searched_months_nums) {
                        if (_num < parseInt(months[0])) {
                            Logger(`AvailableScheduleTag.getPossibleDatesPreview 取得月が異なるため、早期リターンします.`)
                            return null
                        }
                    }
                    // Logger(`取得月が異なるため、早期リターンします.`)
                    // return null
                }
                const { possible_dates, jp_formats } = PossibleDate.fetchFromJson(_dic.possible_dates)
                let mag_dic = _dic.mag_dic
                let daily_possible_dates = _dic.daily_possible_dates
                let owner_request_num = _dic.owner_request_num
                let pdm = _PossibleDatesManager
                let possible_dates_selectable_mag_dic = _dic.possible_dates_selectable_mag_dic
                let selectable_mag_dic = _dic.selectable_mag_dic
                let selectable_mode = _dic.selectable_mode
                let available_months = _dic.available_months
                let available_weeks = _dic.available_weeks
                let weeks = _dic.weeks

                let dic = {
                    possible_dates,
                    mag_dic,
                    daily_possible_dates,
                    owner_request_num,
                    jp_formats,
                    possible_dates_selectable_mag_dic,
                    selectable_mag_dic,
                    selectable_mode,
                    astag_id: _dic.available_time_id || astag?.id,
                    available_months,
                    months: searched_months,
                    available_weeks,
                    weeks: weeks,
                    calendar_show_type: _dic.calendar_show_type,
                }
                pdm.setFromAstagPreview(dic, userInfo, needRefresh)
                /**
                 * 返却値
                 *  possible_dates: possible_dates_with_unixtime,
                    mag_dic: meeting_groups_name_dic(user: user),
                    daily_possible_dates: possible_events_member_dic,
                    owner_request_num:
                 */
                // const form: AppointmentForm = result.data.appointment_form
                return pdm
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    /**
     * 自分のastagを特定のuserGroupに共有します。
     * @param group_id [string] 相手のuser_group_id
     */
    static share(room: Room, astag: AvailableScheduleTag) {
        let group_id = this.getOtherUserGroupId(room)
        return axios
            .post(`${Util.prefixUrl}/available_schedule_tags/share`, {
                other_user_group_id: group_id,
                id: astag.id,
                parent_room_id: room.id,
            })
            .then(res => {
                Logger(`res: ${Util.output(res.data)}`)
                Notice.message = `${res.data.message}`

                return []
            })
            .catch(err => {
                Logger(`err: ${err.message}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static unshare(room: Room, astag: AvailableScheduleTag) {
        let group_id = this.getOtherUserGroupId(room)
        return axios
            .delete(`${Util.prefixUrl}/available_schedule_tags/unshare`, {
                params: {
                    other_user_group_id: group_id,
                    id: astag ? astag.id : null,
                },
            })
            .then(res => {
                Logger(`res: ${Util.output(res.data)}`)
                Notice.message = `${res.data.message}`

                return []
            })
            .catch(err => {
                Logger(`err: ${err.message}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static request(room: Room, message: string) {
        const params = { parent_room_id: room.id, message: message }

        // appointment["parent_room_id"] = ""
        Logger(`request pds: ${Util.output(params)}`)

        return axios({
            method: "post",
            url: `${Util.prefixUrl}/available_schedule_tags/request_other_dates`,
            data: params,
        })
            .then(res => {
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                return null
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static changeDefault(astag: AvailableScheduleTag) {
        const params = { id: astag.id }

        // appointment["parent_room_id"] = ""
        Logger(`request pds: ${Util.output(params)}`)

        return axios({
            method: "put",
            url: `${Util.prefixUrl}/available_schedule_tags/change_default`,
            data: params,
        })
            .then(res => {
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                return res.data.updated
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static rename(astag: AvailableScheduleTag) {
        const params = { id: astag.id, name: astag.name }

        // appointment["parent_room_id"] = ""
        Logger(`request pds: ${Util.output(params)}`)

        return axios({
            method: "put",
            url: `${Util.prefixUrl}/available_schedule_tags/rename`,
            data: params,
        })
            .then(res => {
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                return res.data.name
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return false
            })
    }

    /**
     * オーナーに対してのみ現在のところ共有します。
     */
    static getOtherUserGroupId(_room: Room) {
        let ugId = ""
        // 3者間（親ルーム）の場合、chukaiのug_idを取得して、公開します。
        ugId = _room.owner.user_group_id
        // const my_ud_id = userInfo.user_group_id

        // if (_room.isParent) {
        //     // 3者間（親ルーム）の場合、chukaiのug_idを取得して、公開します。
        //     ugId = _room.owner.user_group_id
        // } else {
        //     // 2者間（子ルーム）の場合、自分以外のmemberのug_idを取得して公開します。
        //     const members = currentRoom.attendees
        //     for (let _mem of members) {
        //         if (my_ud_id !== _mem.user_group_id) {
        //             ugId = _mem.user_group_id
        //             break
        //         }
        //     }
        // }
        return ugId
    }

    /**
     * NONE = :none
        GUESS_OR_NONE = :guess_or_none
        GUESS_OR_FIXED = :guess_or_fixed
        guess_or_minimum
        FIXED = :fixed
     */
    static travelTimeModeOptions() {
        return [
            { mode: `none`, text: `移動考慮不要` },
            { mode: `fixed`, text: `固定時間幅を入れる` },
            { mode: `guess_or_none`, text: `移動考慮し、場所が特定できない場合は入れない` },
            { mode: `guess_or_fixed`, text: `移動考慮し、場所が特定できない場合は固定時間を入れる` },
            { mode: `guess_or_minimum`, text: `移動考慮し、最低固定時間幅と比較` },
        ]
    }

    static changeModeToText(astag: AvailableScheduleTag): string {
        let mode = this.travelTimeModeOptions().find(e => e.mode == astag.travel_time_mode)
        if (mode) return mode.text
        return `移動考慮不要`
    }

    /**
     * リモートから取得してきたastags（JSONパースした後）をそれぞれにセットできるように返却します。
     * @param astags
     */
    static setAstags(astagsData: AvailableScheduleTag[]): any {
        let astag: AvailableScheduleTag, chukaiAstag: AvailableScheduleTag, defaultAstag: AvailableScheduleTag
        let astags = AvailableScheduleTag.fetchFromJson(astagsData) || []
        let roomStorage = new RoomStorage()

        if (astagsData) {
            const astagData = astagsData[0]
            if (astagData) {
                Logger(`astagData 1:: ${Util.output(astagsData)}`)
                // let astags = AvailableScheduleTag.fetchFromJson(astagsData) || []
                // astags = astags
                defaultAstag = astags.find(a => a.type == 2)
                if (!defaultAstag) defaultAstag = astags.find(a => a.type == 1)
                if (!defaultAstag) defaultAstag = astags.find(a => a.type == 50)
                defaultAstag ? (defaultAstag.systemUpdatedAt = Util.getSec()) : null
                // Logger(`取得してきました。 1:: ${Util.output(astag)}`)
                chukaiAstag = astags.find(a => a.type == 10)
                chukaiAstag ? (chukaiAstag.systemUpdatedAt = Util.getSec()) : null
            }
        }

        if (defaultAstag?.id != "newId") {
            astag = defaultAstag
        }

        Logger(`astagを確認します: ${Util.output(astag ? astag.name : `名無し`)}`)
        return { astag, chukaiAstag, defaultAstag, astags }
    }

    /**
     * OptionalSchedulesを追加/変更します。newSchedulesに入っていてもすでに追加されている場合は、追加しません。
     * deleteSchedulesに入っているものはそのタイプのものから削除します。
     * @param ops [any] optional_add_schedules, optional_remove_schedulesの内容
     * @param newSchedules そのschedulesに追加する
     * @param deleteSchedules そのschedulesから削除する
     * @return newOps 反映されたoptional_schedulesを返します。
     */
    static mergeSchedules(ops: any, newSchedules: any[], deleteSchedules: any[]): any {
        let newOps = ops ? { ...ops } : {}
        newSchedules = newSchedules || []
        for (let _sche of newSchedules) {
            let sche = _sche as Event
            let time = DateTime.fromSeconds(sche.start_time)
            let date = time.toFormat(`MMdd`)

            let arr = newOps[date] || ([] as Event[])
            if (arr.some(op => Event.isSame(op, sche))) {
            } else {
                arr.push(sche)
            }
            newOps[date] = arr
        }

        deleteSchedules = deleteSchedules || []
        for (let _sche of deleteSchedules) {
            let sche = _sche as Event
            let time = DateTime.fromSeconds(sche.start_time)
            let date = time.toFormat(`MMdd`)
            let arr = newOps[date] || ([] as Event[])
            arr = arr.filter(op => !Event.isSame(op, sche))
            newOps[date] = arr
        }

        return newOps
    }

    static search(params: any): Promise<AvailableScheduleTag[]> {
        return axios({
            method: "get",
            url: `${Util.prefixUrl}/available_schedule_tags/search`,
            params: params,
        })
            .then(res => {
                const _astags: AvailableScheduleTag[] = AvailableScheduleTag.fetchFromJson(res.data.astags)
                return _astags
            })
            .catch(err => {
                Logger(`err: ${err.message}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static getOpenSchedules(astag: AvailableScheduleTag, userInfo: any, tabName: string): Promise<any> {
        let params = {}
        if (tabName) {
            params["tab"] = tabName
        }

        return axios({
            method: "post",
            url: `${Util.prefixUrl}/available_schedule_tags/open_schedules/${astag.id}`,
            params,
        })
            .then(res => {
                if (res) {
                    let rooms = Room.fetchFromJson(res.data.rooms, userInfo)
                    // this.suggestedRoomUrl = res.data.suggested_room_url
                    // this.generateFixTabRooms()
                    return { rooms, suggested_room_url: res.data.suggested_room_url, tabName: res.data.tab_name }
                }
                return {}
            })
            .catch(err => {
                Logger(`err: ${err.message}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }
}
